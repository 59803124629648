import React, { Component } from 'react';

import { Result } from 'antd';
export default class Page404 extends Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="页面未找到"
            />
        )
    }
}