import { observable, action } from 'mobx';
import { RestAPI } from '../utils/api'
class Cache {
    @observable categorys = null;

    @action.bound
    async categoryList() {
        var data = await RestAPI.invokeServerAsync("/Api/News/GetCategorys");
   
        this.categorys = data.items;
      
    }
}


const self = new Cache();
export default self;