import { observable, action, toJS } from 'mobx';
import { RestAPI } from '../utils/api'
import utils from '../utils';
export default class NewsStore {
    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false
    @observable advertis = [];

    @observable categoryIDs = null;

    @observable pagination = {
        current: 1,
        pageSize: 10,
        total: 0
        
    }

    @observable filter = {
        columnId: ''
    }

    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const { columnId } = this.filter;
            var postData = {
                pageCount: current,
                isPublish:true,
                pageSize
            }
            var category = await RestAPI.invokeServerAsync("/Api/News/GetCategorys");
            this.getParentCategory(columnId, category.items);
            if (!utils.isNullOrEmpty(columnId)) {
                postData.columnId = columnId;
                
            }
            
            postData.categoryIDs = this.categoryIDs;

           
            
            var res = await RestAPI.invokeServerAsync("/Api/News/GetNews", postData);
            console.log("res", res);
            this.pagination = {
                current,
                pageSize,
                total: res.totalResults
            }
           
          
            this.categoryIDs =null;
            this.dataList = res.items
            
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }


    async gets(id, columnId) {
        try {
            this.loading = true;
            var postData = {
                id: id
            };
            var res = await RestAPI.invokeServerAsync("/Api/News/NewsById", { id: id, columnId: columnId });
          

            this.dataInfo = res.info;
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

  
    getParentCategory(columnId,datas) {
        datas.forEach((item) => {
            //带有子集菜单children
            if (columnId == item.categoryID) {
                if (this.categoryIDs == null) {
                    this.categoryIDs = [];
                }
                this.categoryIDs.push(item.categoryID);
                if (!utils.isNullOrEmpty(item.children) && item.children.length > 0)
                    this.childrens(item.children);
            } else {
                if (!utils.isNullOrEmpty(item.children) && item.children.length > 0) {
                    this.getParentCategory(columnId, item.children);
                }
            }
        })
    }

    childrens(datas) {
        datas.forEach((item) => {
            if (this.categoryIDs == null) {
                this.categoryIDs = [];
            }
            this.categoryIDs.push(item.categoryID);
            if (!utils.isNullOrEmpty(item.children) && item.children.length > 0) {
                this.childrens(item.children);
            }
        })
    }

    @action.bound
    async getCard(cardNo) {
        var res = await RestAPI.invokeServerAsync("/Api/Survey/GetCards", {
            //CardNo: cardNo,
            IsActivation: false,
            pageCount: 1,
            pageSize: 1
        });
        console.log("getCard", res);
        return res;
    }

    @action.bound
    async saveWenJuan(params) {
        try {
            this.loading = true;

            var res = await RestAPI.invokeServerAsync("/Api/Survey/DataSheetEdit", { ...params });
            console.log(res);
            return res;
          
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

}