function isNullOrEmpty(s) {
    return s == null || s == '' || s == undefined || s == 'null';
}
function checkIsEmail(s) {
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(s);
}
function checkIsMobile(s) {
    let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
    return reg.test(s);
}
const utils = {
    isNullOrEmpty,
    checkIsEmail,
    checkIsMobile
}

export default utils