import { observable, action, toJS } from 'mobx';
import { RestAPI } from '../utils/api'
import utils from '../utils';
export default class AdvertiStore {
    @observable advertiList = []
 
    @observable loading = false
   

    @observable pagination = {
        current: 1,
        pageSize: 3,
        total: 0

    }

    @observable filter = {
        isPublish: true
    }

    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                isPublish
            } = this.filter;


            var res = await RestAPI.invokeServerAsync("/Api/News/GetNewsAdverti", {
                pageCount: current,
                pageSize,
                isPublish
            });



            this.pagination = {
                current,
                pageSize,
                total: res.totalResults
            }

            this.advertiList = res.items
          
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }


   

 
}