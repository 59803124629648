import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Link } from "react-router-dom";
import { Select, Menu, Dropdown } from 'antd';
import stores from '../../stores'
import utils from '../../utils';
const { SubMenu } = Menu;
export default @observer class CategoryCmpt extends Component {

    @observable categoryActive = "";
    static defaultProps = {

    }

    constructor(props) {
        super(props);
    }
    async componentDidMount() {
        await stores.cache.categoryList()

    }

    componentWillReceiveProps() {

    }

    categoryOnLink = (item) => {
        //新闻详情
        if (!utils.isNullOrEmpty(item.externalLink)) {
            return (
                <Link to={item.externalLink} onClick={() => {
                    this.categoryActive = item.categoryName;
                }} className={this.categoryActive == item.categoryName ? "active" : ""}>{item.categoryName}</Link>
            )
        }
        else if (item.isNewCategoty) {
            return (
                <Link to={`/news/detail?categoryID=${item.categoryID}`} onClick={() => {
                    this.categoryActive = item.categoryName;
                }} className={this.categoryActive==item.categoryName?"active":""}>{item.categoryName}</Link>
            )
        } else {
            return (
                <Link to={`/news/list?category=${item.categoryID}&columnStype=${item.columnStype}`} className={this.categoryActive == item.categoryName ? "active" : ""} onClick={() => {
                    this.categoryActive = item.categoryName;
                }}>{item.categoryName}</Link>
        )}
    }

    renderMenu() {
        const { categorys } = stores.cache;
        return (
            <>
                <Menu theme="light" mode="horizontal">

                    <Menu.Item>
                        <Link to={""} onClick={() => {
                            this.categoryActive = "";
                        }} className={this.categoryActive == "" ? "active" : ""}>首页</Link>
                    </Menu.Item>


                    {categorys.map((cate, i) => {
                        if (cate.children == null || cate.children.length==0) {
                            return (
                                <Menu.Item key={cate.categoryName}>
                                    {this.categoryOnLink(cate)}</Menu.Item>
                            )
                        } else {
                            return (
                                
                                <SubMenu title={this.categoryOnLink(cate)} key={cate.categoryName} >
                                    {this.renderSubMenu(cate.children)}
                                </SubMenu>
                            )
                        }
                    })
                    }

                </Menu>
            </>
        )
    }


    renderSubMenu(array) {
       
        return (
           
           array.length != 0 &&
             < Menu.ItemGroup >
            {
                array.map((item, i) => {

                    if (item.children == null || item.children.length==0) {
                        return (
                            <Menu.Item key={item.categoryName}> {this.categoryOnLink(item)}</Menu.Item>
                        )
                    } else if (item.children != null) {
                        return (
                            <SubMenu title={this.categoryOnLink(item)} key={item.categoryName}>
                                {this.renderSubMenu(item.children)}
                            </SubMenu>
                        )
                    }
                })
            }

            </Menu.ItemGroup >

        )
    }


    render() {
        const { categorys } = stores.cache;
        return (
            <>
                {categorys && this.renderMenu()}
            </>
        )
    }
}