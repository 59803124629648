import cache from './cacheStore'
import newsStore from './newsStore'
import advertiStore from './advertiStore'
export default {
    cache
}


export {
    newsStore,
    advertiStore
}
