import 'whatwg-fetch';
import axios from 'axios';
export class RestAPI {

    static serverData = {
        domain: "https://lvxunadmin.yiqifei.com"
    }


    static invokeServer = (options) => {

        if (process.env.NODE_ENV == 'production') {
            options.method = RestAPI.serverData.domain + options.method ;
        }

        axios.post(options.method,options.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {

            if (res.status == 200) {
                if (typeof options.success === 'function')
                    options.success(res.data);
            }
            else if (typeof options.fail === 'function') {
                options.fail(res.status, res.statusText);
                console.log(options.method + ":", res.statusText);
            }

        }).catch(ex => {
            if (typeof options.fail === 'function') {
                options.fail(-1, ex);
            }
        })


    }

    static invokeServerAsync = async (url, data = {}) => {
        

        if (process.env.NODE_ENV == 'production') {
            url = RestAPI.serverData.domain + url;
        }
        let res = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
       
        if (res.status == 200) {
            return res.data;
        } else {
            return null;
        }
    }

   

  

}

