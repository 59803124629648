import React from 'react';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import 'antd/dist/antd.css';
import routers from './router';
import { Layout, Menu, Row, Col, Popover, Avatar, Input, Drawer, Button, Radio, Dropdown, Space, Modal, Form, Checkbox, Select } from 'antd';
import './App.less';
import lanlogo1 from './images/logo.png';
import logo2 from './images/newlogo2.png';
import qs from 'querystring';
import stores from './stores'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react";
import Page404 from './pages/page404'
import constants from './utils/constants'
import MobileNav from './pages/component/mobileNav';
import { createBrowserHistory } from 'history';
import CategoryCmpt from './pages/component/category.cmpt';
//const IconFont = Icon.createFromIconfontCN({
//    scriptUrl: '//at.alicdn.com/t/font_620694_g09efi51bm.js',
//});
import { createFromIconfontCN } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_620694_24fq1a1o2do.js',
});
const { Option } = Select;
const { Header, Footer, Sider, Content } = Layout;

const browserHistory = createBrowserHistory()
const { SubMenu } = Menu;

//const QueryProvider = withRouter(props => {
//    if (props && props.location && props.location.query == null) {
//        let search = props.location.search
//        if (search) {
//            search = search.substr(1)
//        }
//        // 注入分析后的查询参数
//        props.location.query = qs.parse(search)
//    }
//    return <Switch {...props} />
//})
const menu = (
    <Menu className="down-menu2">
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                个人资料
                    </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                退出登录
                    </a>
        </Menu.Item>

    </Menu>
);
export default @observer class  App extends React.Component {

    
    constructor(props) {
        super(props);
        
        this.state = {
            current: 'sub3-3',
            visible: false,
            placement: 'left',
            visible2: false,
            placement2: 'top',
            topActive: false,
            isModalVisible: false,
            isModalVisible2: false,
            isModalVisible3: false,
        }
    }
    async componentDidMount() { 
        //  window.addEventListener('scroll', this.handleScroll);
        await stores.cache.categoryList();
     
     
    }

    async UNSAFE_componentWillReceiveProps() {
     
    }
   
    handleScroll = (event) => {
        //滚动条高度
        let ctx = this;
        let clientHeight = document.documentElement.clientHeight; //可视区域高度
        let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
        let scrollHeight = document.documentElement.scrollHeight; //滚动内容高度
    
        if (scrollTop > 200) {
            ctx.setState({ topActive: true })
        } else {
            ctx.setState({ topActive:false })
        }
        

    }
    showDrawer = () => {
        this.setState({
            visible: !this.state.visible,
        });
        console.log(1);
    };
    showDrawer2 = () => {
        this.setState({
            visible2: !this.state.visible2,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    onClose2 = () => {
        this.setState({
            visible2: false,
        });
    };


    categoryOnLink = (item) => {
        //新闻详情
        if (item.isNewCategoty) {
            return (
                <Link to={`/news/detail?categoryID=${item.categoryID}`}>{item.categoryName}</Link>
            )
        } else {
            return (
                <Link to={`/news/list?category=${item.categoryID}&columnStype=${item.columnStype}`}>{item.categoryName}</Link>
            )
        }
    }
   
    render() {
        const { visible, visible2, placement2, topActive } = this.state;
        console.log("constants.isApplication", constants.isApplication == false);

        console.log(browserHistory.location.pathname);
        if (browserHistory.location.pathname == '/news/email2') {
            constants.isApplication =true
        }
        const { categorys } = stores.cache;
      
        return (

            <BrowserRouter>
                {!constants.isApplication &&
                    <Header className={topActive ? "news-header fixed-head fixed-head-active" : 'news-header fixed-head fixed-head-noactive'} >
                        <Row type="flex">
                            <Col xs={0} lg={4}>

                                <div className="news-img-left">
                                    {topActive != true && <img src={lanlogo1} width="120px" preview={false} style={{ marginTop: "-5px" }} />}
                                    {topActive == true && <img src={logo2} width="100px" preview={false} />}
                                </div>

                            </Col>
                            <Col xs={0} lg={14}>
                                <CategoryCmpt history={browserHistory} />
                            </Col>
                            <Col xs={0} lg={4}>
                            {/* <div className="news-img-right">
                                    <Input placeholder="搜索G&K" />
                                </div>*/}
                            {/*已登录*/}
                            {false&& <Dropdown overlay={menu} placement="bottomLeft" arrow >
                                <div>
                                    <img src="https://img0.yiqifei.com/p/62038f3e4ec00234e03d1140/touxiang.png" style={{ width: "36px", height: "36px" }} />
                                    <span className="ml10">1368595</span>
                                </div>
                            </Dropdown>
                            }
                            {/*未登录*/}
                            <div>
                            <img src="https://img0.yiqifei.com/p/62038f3e4ec00234e03d1140/touxiang.png" style={{ width: "36px", height: "36px" }} />
                            <span className="ml10">登录</span>
                            </div>
                            {/*未登录*/}
                            {false && <div>
                                <img src="https://img0.yiqifei.com/p/62038f3e4ec00234e03d1140/touxiang.png" style={{ width: "36px", height: "36px" }} />
                                <span className="ml10">未登录</span>
                                <span>|</span>
                                <span>联系我们</span>
                            </div>}
                            </Col>
                        </Row>
                        {/*手机端开始*/}
                        <Row type="flex" justify="space-between">
                            <Col xs={2} lg={0} onClick={this.showDrawer}>
                                {visible == false && <IconFont type="icon-xiangqing" className="news-icon-detail" />}
                                {topActive == false && visible == true && <IconFont type="icon-guanbi" style={{ color: "#000", fontSize: "28px" }} />}
                                {topActive == true && visible == true && <IconFont type="icon-guanbi" style={{ color: "#fff", fontSize: "28px" }} />}
                            </Col>
                            <Col xs={12} lg={0}>
                                <Link to="/">
                                    <div className="news-img-left">
                                        {topActive != true && <img src={lanlogo1} width="79px" preview={false} />}
                                        {topActive == true && <img src={logo2} width="79px" preview={false} />}
                                    </div>
                                </Link>

                            </Col>
                            <Col xs={2} lg={0} onClick={this.showDrawer2}>
                                <IconFont type="icon-chalv-fangdajing" className="news-icon-detail" />
                            </Col>
                        </Row>
                        <Drawer
                            title=""
                            className="news-nav-drawer"
                            placement="top"
                            closable={false}
                            onClose={this.onClose}
                            visible={visible}
                            height="100%"
                            style={visible == true ? { top: "60px", height: "100%" } : ""}
                        >

                            <div className="news-nav news-nav-two">


                                <MobileNav history={browserHistory} onClose={this.showDrawer} />

                            </div>
                        </Drawer>
                        <Drawer
                            title=""
                            placement="top"
                            closable={false}
                            onClose={this.onClose2}
                            visible={visible2}
                            key={placement2}
                            height="auto"
                            style={visible2 == true ? { top: "60px", height: "100%" } : ""}
                        >   <Row>

                                <div className="news-img-right" style={{ width: "100%" }}>
                                    <Input placeholder="搜索G&K网站" />
                                </div>

                            </Row>
                        </Drawer>
                        {/*手机端结束*/}
                    </Header>
                }
                <Layout className="news-box">
                  
                    
                        <Switch>
                    {
                            routers.map((route,index) => {
                                return (
                                    
                                <Route exact
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                            component={route.component} />
                                       
                            )
                        })
                    }
                        <Page404 />
                        </Switch>
                    <Row>
                        <Col lg={24} xs={0}>

                            {!constants.isApplication &&
                                <Footer className="news-f-b">
                                    <div className="news-footer-box">
                                        <div className="news-footer">
                                            <Row align="middle">

                                                <Row type="flex" justify="space-between" className="pd10" style={{ width: "100%" }}>
                                                    <Col lg={18} sm={19}>
                                                        <Row type="flex" className="footer-mobile">
                                                            <Col lg={3} sm={6}>
                                                                <dl>
                                                                    <dt className="news-footer-nav link-th"><Link to="/">首页</Link></dt>
                                                                </dl>
                                                            </Col>

                                                            {categorys && categorys.map((item) => {
                                                                return (
                                                                    <Col lg={4} sm={4}>
                                                                        <dl>
                                                                            <dt className="news-footer-nav"><span className="link-th">{this.categoryOnLink(item)}</span></dt>
                                                                            {item.children != null && item.children.map((cc) => {
                                                                                return (
                                                                                    <dd><span className="link-th">{this.categoryOnLink(cc)}</span></dd>
                                                                                )
                                                                            })}



                                                                        </dl>
                                                                    </Col>
                                                                )
                                                            })}

                                                        </Row>

                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="footer-mobile2">
                                                            <Row type="flex">
                                                                <div>
                                                                    <div className="news-img-left">
                                                                        <img src={logo2} width="180px" preview={false} />
                                                                    </div>

                                                                    <Row type="flex" justify="space-between" className="footer-icon-box">
                                                                        <div className="footer-icon"><IconFont type="icon-lianjie" /></div>
                                                                        <div className="footer-icon"><IconFont type="icon-yqfweixin" /></div>
                                                                        <div className="footer-icon"><IconFont type="icon-unie61d" /></div>
                                                                        <div className="footer-icon"><IconFont type="icon-youxiang" /></div>
                                                                    </Row>

                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Row>
                                            <div style={{ textAlign: "center", marginTop: "40px" }}>
                                                <span onClick={() => { window.location.href = "https://beian.miit.gov.cn/#/Integrated/index" }} className="num-yue">
                                                    粤ICP备2021017643号
                                  </span>
                                            </div>
                                        </div>
                                    </div>

                                </Footer>
                            }
                    </Col>
                    </Row>
                </Layout>

             
            

                   
                </BrowserRouter>
         
        );
    }
}


