import Loadable from 'react-loadable'

//标记： 在定义我们的路由对象，使用react-loadable 对路由组件进行懒加载。
const loadable = (filename) => Loadable({
    loader: () => import(`./pages/${filename}`),
    loading: () => ('')
});


//路由配置对象
const routers = [
    {
        path: '/',
        exact: true,
        component: loadable('index'),
        title:'首页'
    },
    {
        path: '/news',
        exact: true,
        component: loadable('news/index'),
        title: '首页'
    },
    {
        path: '/news/wenjuan',
        exact: false,
        component: loadable('news/wenjuan'),
        title: '问卷'
    },
    {
        path: '/news/company',
        exact: true,
        component: loadable('news/company'),
        title: '公司介绍'
    },
    {
        path: '/news/training',
        exact: true,
        component: loadable('news/training'),
        title: '企业培训'
    },
    {
        path: '/news/OnlineTraining',
        exact: true,
        component: loadable('news/OnlineTraining'),
        title: '线上培训'
    },
    {
        path: '/news/OfflineTraining',
        exact: true,
        component: loadable('news/OfflineTraining'),
        title: '线下培训'
    },
    {
        path: '/news/index2',
        exact: true,
        component: loadable('news/newsIndex2'),
        title: '列表'
    },
    {
        path: '/news/list',
        exact: true,
        component: loadable('news/list'),
        title: '列表'
    },
    {
        path: '/news/detail',
        exact: true,
        component: loadable('news/detail'),
        title: '详情-'
    },{
        path: '/news/wenjuanSuccess',
        exact: false,
        component: loadable('news/wenjuanSuccess'),
        title: '问卷'
    }, {
        path: '/news/wenjuan2',
        exact: false,
        component: loadable('news/wenjuan2'),
        title: '问卷'
    },
    {
        path: '/news/preface',
        exact: false,
        component: loadable('news/preface'),
        title: '前言'
    },
    {
        path: '/news/login/index',
        exact: false,
        component: loadable('news/login/index'),
        title: '登录'
    },
    {
        path: '/news/login/phoneRegister',
        exact: false,
        component: loadable('news/login/phoneRegister'),
        title: '手机注册'
    },
    {
        path: '/news/login/emailRegister',
        exact: false,
        component: loadable('news/login/emailRegister'),
        title: '邮箱注册'
    },
    {
        path: '/news/member/index',
        exact: false,
        component: loadable('news/member/index'),
        title: '个人中心'
    },
    {
        path: '/news/member/myData',
        exact: false,
        component: loadable('news/member/myData'),
        title: '个人资料'
    },
    {
        path: '/news/email',
        exact: false,
        component: loadable('news/email'),
        title: '邮件'
    },
    {
        path: '/news/email2',
        exact: false,
        component: loadable('news/email2'),
        title: '邮件'
    },
    {
        path: '/news/pdf',
        exact: false,
        component: loadable('news/pdf'),
        title: '企业介绍'
    },
  

    

];

export default routers;
